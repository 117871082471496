.App {
  font-family: serif;
  min-height: 100vh;
  text-align: center;
  background-color: #fcf8f2;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-logo {
  max-width: 300px;
}

.App-logo img {
  width: 100%;
}

.App-status-heading {
  margin: 1rem auto;
  font-size: 2rem;
}

.App-status {
  margin: 1rem auto;
  font-family: monospace;
  font-size: 5rem;
  text-transform: uppercase;
}

.App-status--closed {
  color: red;
}

.App-status--open {
  color: green;
}
